<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
        <v-card min-height="600px">
          <router-link :to="{ name: card.link}" style="text-decoration: none">
            <v-img eager :src="require(`@/assets/menu-images/${card.imgPath}`)"
                   class="hover-img" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                   :height="$vuetify.breakpoint.mobile?'350px':'600px'">
              <v-card-title v-text="card.title" class="hover-img-title"></v-card-title>
            </v-img>
          </router-link>
          <v-card-text class="subtitle-1">{{$t(card.desc)}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="action-button" color="primary" block @click="$router.push({ name: card.link })">
              {{$t(card.title)}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "Finance",
    data() {
      return {
        cards: [
          {
            title: this.$t('finance-page.payment-history'),
            imgPath: 'payment-history.jpg',
            flex: 6,
            link: 'Payment history',
            desc: this.$t('finance-page.view-history-desc'),
          },
          {
            title: this.$t('finance-page.payment-details'),
            imgPath: 'payment-details.jpg',
            flex: 6,
            link: 'Payment details',
            desc: this.$t('finance-page.payment-data'),
          },
        ],
      }
    },
  }
</script>

<style>
  .hover-img {
    transition: all 0.2s ease;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    border-radius: 5px 5px 0 0;
  }

  .hover-img-title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2rem;
    justify-content: center;
    color: white;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .hover-img:hover {
    transform: scale(1.01);
    opacity: 0.9;
  }
</style>
